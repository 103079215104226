import type { CartItem } from '@robustastudio/e-commerce/common';

export interface TierGift {
  name: string;
  thumbnail: string;
  stock: number;
  sku: string;
}

export interface PromotionOption {
  is_eligible: boolean;
  tier_minimum_subtotal: number;
  gift: TierGift;
}

export interface CartPromotions {
  options: Array<Partial<PromotionOption> | undefined | null> | undefined | null;
  eligiblePromotionIdx: number;
}

export type ExtendedCartItem = CartItem & { isGift: boolean; isInvalidItem?: boolean };

export type ReviewNode = {
  createdAt: Date;
  nickname: string;
  averageRating: Float32Array;
  text: string;
  summary: string;
};

export type ErrorMapping = {
  [key: string]: string;
};

export enum OTPContext {
  'REGISTER' = 'register',
  'FORGET' = 'forget',
}
